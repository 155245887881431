body {
  background-color: #f3f3fc !important;
  font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
}

#heedifyRoot,
body,
html {
  height: 100%;
}

#queues-mean-div {
  padding-left: 30px;
  padding-right: 30px;
}

.queue-name {
  color: #6264a7;
  font-size: 18px;
  font-weight: 400;
}

.queues-small-elements-div {
  display: flex;
  justify-content: space-between;
}

#heedifyRoot {
  background-color: #f5f5f5 !important;
}

.nav {
  margin-bottom: 30px !important;
  position: absolute;
  right: 0;

}

.nav a {
  color: rgb(142, 68, 173) !important;
}

.App {
  margin-top: 3px !important;
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Css of components related to the report*/
.Rapport {
  text-align: center;
  background-color: white;
}

#NomberAgant,
#PerfermanceAgent {
  margin: 10px;
  border-radius: 10px;
  height: 150px;
  font-size: medium;
}

#NomberAgant button {
  width: 40px !important;
  display: inline-block;
  margin: 10px;
}

#PerfermanceAgent span {
  vertical-align: middle;
  border-radius: 5px;
  padding: 8px;
  margin-top: 8px;

}

.separation {
  margin-top: 30px;
  height: 3px;
  background-color: black;
}

.separation h1 {
  margin-top: 30px;
}

hr {
  margin-top: 50px;
}

.result {
  color: white !important;
}

.App {
  margin-top: 100px;
}

.Myrapport {
  margin: auto;
  height: auto;
  background-color: white;
}

#userValue {
  width: 60px;
}

#Alert {
  height: auto;
  padding: 0px;
  background-color: indianred;
  font-size: x-small;
  color: white;
}

.Heedify-header-logo {
  position: absolute;
  width: 145px;
  margin-left: 22px;
  margin-top: 4px;
}

.queue-item-small-value {
  font-size: 30px !important;
  color: #045089 !important;
}

.queue-item-value {
  font-size: 30px !important;
  color: #045089 !important;
}

#hide {
  display: none;
}

#show {
  display: block;
}

.queue-item-title {
  min-height: 40% !important;
  margin-bottom: 0px !important;
  color: #666666 !important;
  font-weight: 600 !important;
}

.timer {
  height: auto !important;
  border-radius: 0px !important;
  color: rgba(58, 57, 57, 0.9) !important;
  font-size: 12px !important;
  margin-right: 3px;
}

.agent-caller-div {
  width: 25%;
}

.agent-direction-call {
  width: 5%;
}

.agent-person-div {
  width: 33%;
}

.agent-mean-div .details-41 {
  max-width: 9.6vw !important;
}

.agent-origin-div {
  width: 40%;
  display: flex;
  justify-content: space-between;
  padding-right: 8px
}

.agent-perfermance-item {
  text-align: center;
  width: 20%;
  font-size: 12px
}

.agent-perfermance-item-value {
  color: #045089;
  font-size: 20px;
}

.agent-mean-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  margin-bottom: 5px;
}

.agents-mean-div {
  padding-left: 50px;
  padding-right: 50px;
}

.green-SAL-div {
  color: green;
  font-size: 30px;
}

.red-SAL-div {
  color: red;
  font-size: 30px;
}

.realTimeElement {
  background-color: white;
  text-align: center;
  min-height: 45px;
  padding: 0px 5px 5px;
  width: 20%;
}

.realTimeElement p {
  font-size: 12px;
  margin-bottom: 0px !important;
}

.call_time_parent {
  background-color: #f5f5f5;

}

.realTimeElement span {
  font-size: 33px;
}

.ConnexionToBackEnd {
  width: -webkit-fill-available !important;
  margin: 0px;
  background-color: #f5f5f5;

}


.ConnexionToBackEnd h2 {
  margin-bottom: 10px;
  background-color: #e8e8e8;
  line-height: 2.2 !important;

}

#chartjs-dashboard-pie {
  display: block;
  width: 280px;
  height: 150px;


}

.chartjs-render-monitor {
  animation: chartjs-render-animation 0.001s;
}

.UserAvatar {
  display: inline-block;
}

.apexcharts-legend-marker {
  color: rgb(0, 227, 150);
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 12px;
  float: right;

}

.DivOfUsers {
  display: inline-block;
  margin: 5px;
  height: 35px !important;
}

.ListOnligne,
.ListOfligne,
.ListOfBusy {
  margin: 15px !important;

}

.ListOfligne {
  margin-top: 20px;
  margin-left: 10px !important;
}

.ListOnligne .apexcharts-legend-marker {
  background: green !important;
}

.ListOfligne .apexcharts-legend-marker {
  background: red !important;
}

.ListOfBusy .apexcharts-legend-marker {
  background: gray !important;
}



.ConnexionToBackEnd h2 {
  text-align: center;
}

#nav-dropdown:focus {
  background-color: rgb(142, 68, 173);
  color: white;
}

#nav-dropdown {
  background-color: transparent !important;
}

.badge {
  color: #11a86d !important;
  background-color: transparent !important;
  font-size: 15px;
  float: right;
  font-weight: 500 !important;

}

.value-updated {
  font-size: 45px !important;
  color: rgb(142, 68, 173);
}

#Queu1 {
  vertical-align: middle !important;
  margin-left: 2% !important;
}

.preloader-icon {
  margin: auto;

}

.call_time h6 {
  font-size: 1.1 rem !important;
}

.call_time {
  height: fit-content !important;
  background-color: white;
}

.Queu_name_parent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 2px;
}

.Queu_name {
  writing-mode: vertical-rl;
  top: 50%;
}

.Queu_name h4 {
  transform: rotateZ(180deg);
  margin: 0px !important;
  padding: 8px;

}

.paramQueu {
  font-size: 1rem;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
}

.badgeLive {
  position: relative;
  right: 3px;
}

.rowOfQueu {
  margin: 20px !important;
}

.donut {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AgentList {
  display: flex;
  align-items: center;

}

mgt-people {
  font-size: 10px;
  --list-margin: 8px 4px 8px 8px;
  /* Margin for component */
  --avatar-margin: 0 4px 0 0;
  /* Margin for each person */
  --color: #000000
}

.line1 {
  font-size: 12px !important;
}

.AgentList h6 {
  margin-top: -10px !important;
}

.parent-div {
  background-color: #f5f5f5;
  margin: 15px 20px 20px 30px;
}

.name-queue {
  margin-left: 20px;
}

.name-queue h4 {
  font-size: 1.15rem;
  font-weight: 550;
  margin-bottom: 10px;
  color: rgb(142, 68, 173);
}

.title-dashboard {
  font-size: 1.5rem !important;
}

.in-queue,
.average-wait-time,
.wait-time {
  margin-top: 50px;
}

.in-queue span,
.average-wait-time span {
  font-size: 75px;
  color: rgb(142, 68, 173);
}

.wait-time #success {
  font-size: 75px;
  color: red;
}

.wait-time #alert {
  font-size: 75px;
  color: green;
}